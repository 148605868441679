import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    <h1>Contact Us</h1>
    <p>VNSP Net</p>
    <p>Address: 80/47 Luu Chi Hieu, Tan Phu District, Ho Chi Minh City, Vietnam.</p>
    <p>Phone number: <a href="tel:+84908805309">+84908805309</a>.</p>
    <p>Email: <a href="mailto:git@vnsp.net">git@vnsp.net</a>.</p>
  <form action="https://send.pageclip.co/ttpusNTQGTxdpZRIuvKn8NvMwYGwlL25/vnsp" class="pageclip-form" method="post">
  Name:<br />
  <input type="text" name="name" class="form-w" /><br />

  Email:<br />
  <input type="email" name="email" class="form-w" /><br />

  Message:<br />
  <textarea name="message" rows="2" class="form-w"></textarea><br />

  <br />
  <button type="submit" class="pageclip-form__submit">
    <span>Send</span>
  </button>
</form>
 <script src="https://s.pageclip.co/v1/pageclip.js" charset="utf-8"></script>
  </Layout>
)

export default ContactPage
